'use client'

import React, { useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { useRouter } from 'next/navigation';
import { API_BASE_URL } from '../lib/common';
import { DisplayConceptRef } from '../lib/api';

// Represents one option in the AsyncSelect dropdown.
// TODO: these are essentially DisplayConceptRefs, and we do a lot of translating
// to/from that.  Can we just use DisplayConceptRef directly?
export type SelectItem = {
  value: string;
  label: string;
}

const parseResponse = (data: DisplayConceptRef[]): SelectItem[] => {
  return data.map(item => ({
    value: item.cref,
    label: item.display,
  }));
};

const promiseOptions = (inputValue: string): Promise<SelectItem[]> => {
  if (inputValue === "") return Promise.resolve([]);
  return fetch(`${API_BASE_URL}/schema/prefix_search/${inputValue}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data: DisplayConceptRef[]) => parseResponse(data))
    .catch(error => {
      console.error('Error fetching data: ', error);
      return [];
    });
};

// By default, TopicSelector will on selection navigate to the topic page.
// You can override this by passing in a different onChange function.
interface TopicSelectorProps {
  instanceId: string;
  onChangeFn?: (value: SelectItem | null) => void;
}

export const TopicSelector: React.FC<TopicSelectorProps> = ({ instanceId, onChangeFn }) => {
  const router = useRouter();
  const inputRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/' && !isUserTyping()) {
        event.preventDefault();
        inputRef.current && (inputRef.current as any).focus();
      }
    };

    const isUserTyping = () => {
      const activeElement = document.activeElement;
      return activeElement instanceof HTMLInputElement || 
             activeElement instanceof HTMLTextAreaElement;
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleChange = onChangeFn ?? ((selectedOption: SelectItem | null) => {
    if (selectedOption === null) {
      console.log("TopicSelectorNew: null topic");
      return;
    }
    router.push(`/guide/${selectedOption.value}`);
  });

  return (
    <div role="search">
      <label htmlFor={`topic-selector-${instanceId}`} className="sr-only">
        Search topics
      </label>
      <AsyncSelect
        inputId={`topic-selector-${instanceId}`}
        instanceId={instanceId}
        cacheOptions   // TODO: figure out how to invalidate cache?
        loadOptions={promiseOptions}
        defaultOptions
        placeholder="Search topics..."
        onChange={handleChange}
        className="topic-selector"
        classNamePrefix="react-select"
        ref={inputRef}
        aria-label="Search topics"
        noOptionsMessage={() => "No topics found"}
        loadingMessage={() => "Loading topics..."}
      />
    </div>
  );
};
