//////////////////////////////////////////////////////////////////////////////
//
// Shared functions and components
//
// TODO: split this up into separate files

"use client"  /* TODO: figure out what can be server-side */

import React, { useState, useEffect, useRef, ReactNode, useMemo, useId } from "react";
import Link from 'next/link';
import Image from "next/image";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { DisplayConceptRef, DocNode, DisplayUserFollow, GuideJsonResp, updateUserCommunities, updateUserFollows, UpdateUserFollowsRequest, DisplayUser } from '@/lib/api';
import ReactMarkdown, { Components } from 'react-markdown';
import { SelectItem, TopicSelector } from '../components/TopicSelector';
import { DisplayMentionedConcept } from "./api";
import { ComposeBox } from '../components/ComposeBox';

// Using proxying for now for both normal API requests as well as websockets.
// This takes some configuration to set up.
export const API_BASE_URL = "/api";
export const WS_API_BASE_URL = "/wsapi";


//////////////////////////////////////////////////////////////////////////////
//
// General purpose helper functions
//

export const topic_url = (cref: string): string => `/guide/${encodeURIComponent(cref)}`;

export const sku_url = (cref: string): string => `/products/${encodeURIComponent(cref)}`;

export const strip_en = (s: string): string => s.replace("@en:", "");

// TODO: this should be more principled...., also consolidate with User::is_llm()
// on the rust side.
export const isLLM = (userId: string): boolean => {
  const llmIds = ["gpt-4o", "mixtral8x7B", "llama31-70B"];
  return llmIds.includes(userId);
};


//////////////////////////////////////////////////////////////////////////////
//
// Simple shared or general purpose components
//

interface UserLinkProps {
  username: string;
}

export const UserLink: React.FC<UserLinkProps> = ({ username }) => (
  isLLM(username) ? 
    <span aria-label={`AI model ${username}`}>{username}</span> : 
    <Link href={`/citizen/${encodeURIComponent(username)}`} aria-label={`View profile of ${username}`}>{username}</Link>
);

// TODO: Consolidate
interface ConceptDef {
  sup?: string[];
  sub?: string[];
}

interface ExploreLinksProps {
  cdef: ConceptDef;
}

export const ExploreLinks: React.FC<ExploreLinksProps> = ({ cdef }) => (
  <nav className="content explore-links" aria-label="Concept hierarchy">
    {cdef.sup && cdef.sup.length > 0 && (
      <div className="explore-section">
        <h3 id="broader-concepts">Broader concepts:</h3>
        <ul className="no-bullets" aria-labelledby="broader-concepts">
          {/* TODO: taking the arbitrary first n; should score */}
          {cdef.sup.slice(0, 5).map((sup) => (
            <li key={sup}>
              <Link href={topic_url(sup)} aria-label={`Broader concept: ${strip_en(sup)}`}>{strip_en(sup)}</Link>
            </li>
          ))}
        </ul>
      </div>
    )}
    {cdef.sub && cdef.sub.length > 0 && (
      <div className="explore-section">
        <h3 id="subcategories">Subcategories:</h3>
        <ul className="no-bullets" aria-labelledby="subcategories">
          {/* TODO: taking the arbitrary first n; should score */}
          {cdef.sub.slice(0, 20).map((sub) => (
            <li key={sub}>
              <Link href={topic_url(sub)} aria-label={`Subcategory: ${strip_en(sub)}`}>{strip_en(sub)}</Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  </nav>
);

interface GuideSummaryContentProps {
  guide: GuideJsonResp;
}

export const GuideSummaryContent: React.FC<GuideSummaryContentProps> = ({ guide }) => (
  <div>
    <ReactMarkdown>{guide?.summary ?? ""}</ReactMarkdown>
  </div>
);

//////////////////////////////////////////////////////////////////////////////
//
// A general purpose container for content
//

interface ContentCardProps {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  collapsible: boolean;
  initialState?: boolean;
}

export const ContentCard: React.FC<ContentCardProps> = ({ title, children, collapsible, initialState = true }) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const contentId = useId();

  const content = useMemo(() => {
    if (collapsible) {
      const childArray = React.Children.toArray(children);
      if (childArray.length !== 2) {
        console.warn('ContentCard: Collapsible content should have exactly two children');
        return null;
      }
      return isOpen ? childArray[1] : childArray[0];
    }
    return children;
  }, [collapsible, children, isOpen]);

  return (
    <article className="content">
      {title && <h3 className="content-title">{title}</h3>}
      <div className="content-wrapper">
        <div 
          id={contentId}
          className={`collapsible__content ${isOpen ? 'collapsible__content--open' : ''}`}
          aria-hidden={collapsible && !isOpen}
        >
          {content}
        </div>
        {collapsible && (
          <div className="toggle-wrapper">
            <button 
              className="collapsible__toggle"
              onClick={() => setIsOpen((prev) => !prev)}
              aria-expanded={isOpen}
              aria-controls={contentId}
              aria-label={isOpen ? "Collapse content" : "Expand content"}
            >
              <span className="sr-only">{isOpen ? "Collapse" : "Expand"}</span>
              {isOpen ? <FaChevronUp aria-hidden="true" /> : <FaChevronDown aria-hidden="true" />}
            </button>
          </div>
        )}
      </div>
    </article>
  );
};

interface ToggleContainerProps {
  title?: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const ToggleContainer: React.FC<ToggleContainerProps> = ({ title, children, onClose }) => (
  <div className="toggle-container">
    <div className="toggle-container-content">
      {title && <h2>{title}</h2>}
      {children}
    </div>
  </div>
);

//////////////////////////////////////////////////////////////////////////////
//
// Functions for accessing and updating info about the current logged-in user
// from the backend
// 

export const followsMap = (follows: DisplayUserFollow[] | null): Map<string, DisplayConceptRef[]> => {
  const followsMap = new Map<string, DisplayConceptRef[]>();
  if (follows != null) {
    follows.forEach((follow) => {
      followsMap.set(follow.username, follow.topics);
    });
  }
  return followsMap;
};

export const addUserInfoCommunity = async (username: string, topic: string): Promise<DisplayUser> => {
  return updateUserCommunities(username, { add_communities: [topic] });
};

export const remUserInfoCommunity = async (username: string, topic: string): Promise<DisplayUser> => {
  return updateUserCommunities(username, { rem_communities: [topic] });
};

// TODO: should the caller pass in all this info, or just the username, and let
// the component do the loading?  Or, should we pass in a structured follow object?
interface FollowButtonProps {
  authUsername: string;
  followUsername: string;
  currentlyFollowing: boolean;
  topics: DisplayConceptRef[];
  onFollowUpdate?: (updatedUserInfo: DisplayUser) => void;
}

export const renderTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  const now = new Date();
  
  if (date.toDateString() === now.toDateString()) {
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}m`;
    } else {
      return `${Math.floor(diffInSeconds / 3600)}h`;
    }
  }
  
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
  }
  
  return date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' });
};

export function FollowButton({ authUsername, followUsername, currentlyFollowing, topics, onFollowUpdate }: FollowButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<DisplayConceptRef[]>(topics);
  const modalId = useRef(`follow-modal-${Math.random().toString(36).substr(2, 9)}`);

  const handleOpenModal = () => {
    setShowModal(true);
    setSelectedTopics(topics);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTopics([]);
  };

  const handleFollowUnfollow = async () => {
    try {
      const request: UpdateUserFollowsRequest = {
        username: authUsername,
        follow: followUsername,
        action: currentlyFollowing ? "Unfollow" : "Follow",
        topics: currentlyFollowing ? [] : selectedTopics.map(topic => topic.cref),
      };
        
      const updatedUserInfo: DisplayUser = await updateUserFollows(authUsername, request);
      if (onFollowUpdate) {
        onFollowUpdate(updatedUserInfo);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error following/unfollowing user:', error);
    }
  };

  const updateFollowTopics = async () => {
    try {
      const request: UpdateUserFollowsRequest = {
        username: authUsername,
        follow: followUsername,
        action: "Update",
        topics: selectedTopics.map(topic => topic.cref),
      };
        
      const updatedUserInfo: DisplayUser = await updateUserFollows(authUsername, request);
      if (onFollowUpdate) {
        onFollowUpdate(updatedUserInfo);
      }
      handleCloseModal();
    } catch (error) {
      console.error('Error updating follow of user:', error);
    }
  };

  const handleTopicSelect = (selectItem: SelectItem | null) => {
    if (selectItem) {
      const topic: DisplayConceptRef = {
        cref: selectItem.value,
        display: selectItem.label,
      };
      setSelectedTopics(prevTopics => [...prevTopics, topic]);
    }
  };

  return (
    <>
      <button 
        onClick={handleOpenModal} 
        className="follow-button"
        aria-haspopup="dialog"
        aria-expanded={showModal}
      >
        {currentlyFollowing ? `Following` : `Follow`}
      </button>
      {showModal && (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby={`${modalId.current}-title`}>
          <div className="modal">
            <div className="modal-content">
              <h2 id={`${modalId.current}-title`}>
                {currentlyFollowing ? 'Unfollow' : 'Follow'}
                {' '}{followUsername}
                {' '}{currentlyFollowing ? 'or update topics:' : 'on:' }
              </h2>

              {selectedTopics.length === 0 ? (
                <ul>
                  <li><i>All topics</i></li>
                </ul>
              ) : (
                <div className="selected-topics">
                  <ul>
                    {selectedTopics.map((topic, index) => (
                      <li key={index} className="selected-topic-item">
                        <span>{topic.display}</span>
                        <button 
                          className="remove-topic-button" 
                          onClick={() => setSelectedTopics(prevTopics => prevTopics.filter((_, i) => i !== index))}
                          aria-label={`Remove topic ${topic.display}`}
                        >
                          <span aria-hidden="true">✕</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div>
                <label htmlFor="topic-selector">
                  {selectedTopics.length === 0 ? 'Or select topics:' : 'Add topic:'}
                </label>
                <TopicSelector 
                  instanceId='follow-button-topic-selector' 
                  onChangeFn={handleTopicSelect}
                />
              </div>

              <div className="modal-buttons">
                {currentlyFollowing && (
                  <button onClick={updateFollowTopics} className="confirm-button">
                    Update Topics
                  </button>
                )}
                <button onClick={handleFollowUnfollow} className="confirm-button">
                  {currentlyFollowing ? 'Unfollow' : 'Follow'}
                </button>
                <button onClick={handleCloseModal} className="cancel-button">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface JoinButtonProps {
  authUsername: string;
  community: DisplayConceptRef;
  currentlyJoined: boolean;
  onJoinUpdate?: (updatedUserInfo: DisplayUser) => void;
}

// TODO: integrate this with the user Follow mechanism and button
export const JoinButton: React.FC<JoinButtonProps> = ({ authUsername, community, currentlyJoined, onJoinUpdate }) => {
  const handleJoin = async () => {
    try {
      const updatedUserInfo: DisplayUser = currentlyJoined
        ? await remUserInfoCommunity(authUsername, community.cref)
        : await addUserInfoCommunity(authUsername, community.cref);
      if (onJoinUpdate) {
        onJoinUpdate(updatedUserInfo);
      }
    } catch (error) {
      console.error('Error joining community:', error);
    }
  };

  return (
    <button onClick={handleJoin} className="join-button">
      {currentlyJoined ? `Unfollow` : `Follow`}
    </button>
  );
};
